/* @media only screen and (max-width: 650px) {
    .team-pic{
        width: 200px !important;
        height: 200px !important;
        border-radius: 100px;
    }
}

@media only screen and (min-width: 650px) {
    .team-pic{
        width: 250px !important;
        height: 250px !important;
        border-radius: 150px;
    }
} */

.team-btn{
    background: linear-gradient(45deg, #F31260, #F5A524) !important;
}

.team-btn-2{
    color: white !important;
}
