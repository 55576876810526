.ig-icon{
    padding: 2px;
    border-radius: 10px;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
    margin: 0px 8px;
}
.ig-icon:hover{
    cursor: pointer;
}

.fb-icon{
    background-color: #3b5998;
    margin: 0px 8px;
    border-radius: 5px;
    padding: 2px
}
.fb-icon:hover{
    cursor: pointer;
}

.t-icon{
    color: #00acee;
    margin: 0px 8px;
}
.t-icon:hover{
    cursor: pointer;
}

@media screen and (max-width: 650px) {
    .desktop {
        display: none;
    }
}
@media screen and (min-width: 650px) {
    .mobile {
        display: none;
    }
}