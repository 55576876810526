.homeslotspage {
    position: relative;
}

.homeslotspage:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-image: url('../../../assets/images/TrainingEvening\ 2.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    border-radius: 10px;
}

@media screen and (min-width:650px) {
    .homeslotspage:before{
        border-radius: 20px;
    }
}

@media screen and (max-width:650px) {
    .homeslotspage:before{
        border-radius: 10px;
    }
}

.hssbackg {
    position: relative;
    padding: 5% 10% 2.5% 10%;
}
.hssquote{
    text-align: center;
}
