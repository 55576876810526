.slotbookingpage{
    position: relative;
}

.slotbookingpage:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-image: url('../../assets/images/FieldBirdViewPurple.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}
  
.sbbackg {
    position: relative;
    border-color: white !important;
    border-width: 5px;
    padding: 3%;
}

.calendly-inline-widget, 
.calendly-inline-widget *, 
.calendly-badge-widget, 
.calendly-badge-widget *, 
.calendly-overlay, 
.calendly-overlay *{
    border-radius: 20px !important;
}