.apl5page{
    position: relative;
}

.apl5page:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-image: url('../../assets/images/Black.jpeg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}
  
.apl5backg {
    position: relative;
    border-color: white !important;
    border-width: 5px;
    padding: 3%;
}