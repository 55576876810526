
.carousel-heading-slots{
    background: -webkit-linear-gradient(#0072b1, #359ab9, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.carousel-heading-seasons{
    background: -webkit-linear-gradient(#d7353d, #e69898);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.carousel-heading-awards{
    background: -webkit-linear-gradient(#015956, #3ba076 , #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.carousel-root{
    display: flex;
    justify-content: center;
}

.carousel.carousel-slider{
    width: 98% !important;
}