.player-picture{
    width: 175px !important;
    height: 200px !important;
    border-radius: 8px;
    object-fit: cover;
}

.user{
    color: #F31260
}
.book{
    color: #0072F5;
}
.dollar{
    color: #17C964;
}
.soccerball{
    color: #7828C8;
    transform: rotateZ(90deg);
}
.team{
    color: #F5A524;
}
.chat{
    margin-top: 5px;
    color: #F5A524;
}

.team-avatar{
    position: absolute;
    top: -70%;
    left: 70%
}


