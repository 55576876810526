/* aplfantasy.css */

/* Fantasy Game */

.title-container
{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px;
}

.fantasytitle {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-size: 59px;
  line-height: 72px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;

  color: #ffffff;

  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  margin-bottom: 30px;
}
@media only screen and (max-width: 768px)  {

  .addplayerButton
  {
    position: relative;
    width: "25px";
    height: "25px";
  }
.info-icon{
  height: "16px";
}
  
.fantasytitle {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-size: 35px;
  /* line-height: 72px; */
  display: flex;
  align-items: center;
  /* letter-spacing: 0.15em; */
  text-transform: uppercase;
  text-decoration: underline;
  color: #ffffff;

  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-align: center;
}

.apl-font{
  text-align: center;
  
}

}

.nextui-collapse-title {
  color: black;
}

.player-row {
  display: flex;
  justify-content: center; /* Center the players in the row */
  margin-bottom: 10px; /* Adjust as needed for spacing */
}
.sponsor-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: scale(0.8);
  transform-origin: center;
}

.rules-text {
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.03em;
  font-weight: 700;
}

.rules-text2 {
    font-family: "Montserrat";
    font-style: normal;
    letter-spacing: 0.03em;
    font-weight: 600;
  }

/* Money Left Section */
.money-left {
  background-color: #282828; /* The green background color */
  color: white; /* Black color for the text */
  font-weight: bold; /* Bold font weight */
  font-size: 24px; /* Large font size for visibility */
  text-align: center; /* Center the text */
  padding: 15px 20px; /* Padding around the text */
  border-radius: 4px; /* Rounded corners */
  margin-bottom: 10px; /* Margin at the bottom */
  width: 100%; /* Full width to match the design */
  /* money left */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: #ffffff;
  /* width: 302px; */
  height: 86px;
}

.nextui-c-hPzDAx .nextui-collapse-title-content h3 {
  color: black;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;

  letter-spacing: 0.24em;

  color: #282828;
}

.dropdown-item
{
  width: 100%;
}

.coll-dropdown {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-bottom: 10px;
}

.money-left-text {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: #ffffff;
}

.fantasy-game-container {
  color: #fff;
  background-color: #1a1a1a;
  padding: 20px;
  padding-bottom: 50px;
  display: flex; /* Default layout */
  align-items: center;
  justify-content: center;
}

/* Media query for phones */
@media only screen and (max-width: 768px)  {
  .fantasy-game-container {
    flex-direction: column; /* Stack children vertically on small screens */
  }
  .football-field
  {
      background-size: cover !important;

  }
  .player-jersey img
  {
    height: 3vh;
  }
  
}




.fanup-logo {
  width: 266px;
  height: 77px;
  margin-bottom: 1vh;
}

.apl-font {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
}

/* GENDER DROPDOWN */
.nextui-c-hPzDAx .nextui-collapse-title-container {
  border-radius: 10px;
  border-bottom: black;
}
.sponsortext {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.21em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 20px;
}

.leftcol {
  width: 70vw; /* You may adjust this value to find the best fit for your layout */
  align-items: center;
  align-self: center;
  justify-content: center;
}

.tutorial-modal{
  width: 800px !important;
}
@media only screen and (min-width: 768px) {
    .nextui-c-grJsex-ikUaMfZ-css{
      max-width: 1000px !important;
    }
    .nextui-c-grJsex-ikUaMfZ-css 
    {
      width: 50vw !important; 
    }
}

@media only screen and (max-width: 768px) {
    .nextui-c-grJsex-ikUaMfZ-css 
    {
      width: 100vw !important; 
    }
}

.football-field {
  background: url("footballfield.png") no-repeat center; /* This will align your background to the left */
  background-size: contain;
  position: relative;
  width: 100%; /* Make this 100% of the parent container's width */
  height: 75vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
  margin-left: 0; /* Adjust or remove any left margin if needed */
  margin-right: auto; /* This will keep the field aligned to the left */
}
.rightcol {
  display: flex;
  flex-direction: column;
  width: 25vw;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.rightcolcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-jersey {
  position: relative;
  text-align: center;
  justify-content: center;
  width: 150px; /* Reduce the width to the size that matches your second image */
}
.midfielder-1 {
  height: 20%;
}

.player-name-bg,
.player-price-bg {
  padding: 0px 4px; /* Reduce padding to decrease the height */
  width: auto; /* This will only be as wide as the content, plus padding */
  font-size: 8px; /* Reduce the font size to match your second image */
}

.player-name-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1f335b;
  height: 20px;
}

.player-price-bg {
  background-color: #f4f4f4;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* General scrollbar styles */
.sidebar::-webkit-scrollbar {
  width: 10px; /* Adjust the width of the scrollbar */
}

/* Track styles */
.sidebar::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Light grey track */
  border-radius: 10px; /* Rounded corners for the track */
}

/* Handle styles */
.sidebar::-webkit-scrollbar-thumb {
  background-color: #888; /* Dark grey handle */
  border-radius: 10px; /* Rounded corners for the handle */
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: white; /* Darker grey handle on hover */
}

.player-name-text {
  height: 100%;
  width: 100%;
  justify-content: center;
  justify-self: center;
  text-align: center;
  margin-bottom: 0;
  font-size: 14px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

.player-price-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
}



@media only screen and (max-width: 768px)  {
  .player-name-text {
    height: 100%;
    width: 100%;
    justify-content: center;
    justify-self: center;
    text-align: center;
    margin-bottom: 0;
    font-size: 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
  
    color: #ffffff;
  }
  
  .player-price-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
  }
}

.player-jersey img {
  height: 12vh;
}

.sidebar {
  width: 80%;
  height: auto;
  background-color: #333;
  padding: 12px;
  border-radius: 8px; /* Adjust if needed to match the corner radius */
  height: auto; /* Set to auto so it grows with content */
  margin: 0 0 20px 0; /* Adjust the margin as needed */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; /* Center the items on cross-axis */
  width: 311px;
  height: 766px;
  background: #666666;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden;
}

.nextui-c-lfcDHB {
  width: 100%;
  display: block;
  text-align: left;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0 0 0px 0 !important;
}
.coll-group {
  padding: 0;
  border: white;
  /* background-color: white; */
  border-radius: 4px;
  margin-bottom: 8px;
}
.sidebar h3 {
  font-size: 18px; /* Adjust the font size as needed */
  color: #fff;
  margin-bottom: 40px; /* Space between 'Money Left' and dropdown */
  font-weight: 700; /* Bold font weight */
  text-align: center;
  width: 100%; /* Full width */
}

.sidebar .dropdown-button {
  font-size: 18px; /* Adjust the font size as needed */
  text-transform: uppercase; /* Uppercase text */
  margin-bottom: 30px; /* Space between dropdown and 'Selected Players' */
  font-weight: 500; /* Medium font weight */
}

.sidebar ul {
  list-style: none;
  padding: 0;
  width: 100%; /* Full width */
}

/* aplfantasy.css */

/* Styles for the player card */
.player-card {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.player-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.add-player-btn {
  background-color: #21ba45; /* Green background */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px; /* Space between the button and the player info */
}

.info-modal {
  background: #1f335b;
  border-radius: 5px;
  width: 100%;

}

@media only screen and (max-width: 768px)  {
  .info-modal {
    background: #1f335b;
    border-radius: 5px;
    width: 100vw !important;
  }
}


/* Add any other styles as needed */

.players-list {
  background-color: #4dffa8; /* Background color for list items */
  color: black; /* Text color for player name */
  margin-bottom: 10px;
  padding: 15px 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column; /* Stack name and position on top of each other */
  align-items: flex-start; /* Align items to the left */
  width: 100%;
  cursor: pointer;
}

.players-card {
  background-color: #4dffa8; /* Background color for list items */
  color: black; /* Text color for player name */
  margin-bottom: 10px;
  padding: 15px 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column; /* Stack name and position on top of each other */
  align-items: flex-start; /* Align items to the left */
}

.sidebar li .player-name {
  font-size: 18px; /* Adjust the font size as needed for the name */
  font-weight: 500; /* Medium font weight for the name */
}

.sidebar li .player-position {
  font-size: 14px; /* Smaller font size for the position */
  color: black; /* Text color for the position */
  font-weight: 300; /* Lighter font weight for the position */
  margin-top: 5px; /* Space between name and position */
}


.nextui-collapse-title {
  font-size: 8px;
  padding: 8px 16px;
  color: white;
}

.player-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px; /* Add padding to match the design's spacing */
}

.player-modal-image {
  /* Style adjustments for better centering */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 175.26px;
  /* 395b549b-f923-4e5b-9935-f649249f6178 Background Removed 1 */
}

.player-team-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px; /* Adjust spacing between team info and player name */
}

.player-modal-team-logo {
  width: 30px; /* Adjust size to match the design */
  height: 30px;
  margin-right: 10px; /* Space between logo and team name */
  border-radius: 50%; /* Full roundness */
  /* Dolla Sign 1 */

  width: 40px;
  height: 40px;

  border-radius: 90px;
}
@media only screen and (min-width: 768px) {
    .player-modal-team-name {
      /* Adjust to more closely resemble the design */
      font-size: 1em; /* Smaller font size */
      letter-spacing: normal; /* Adjust letter spacing */
      margin-bottom: 0; /* Remove bottom margin */
      text-transform: none; /* Remove uppercase */
      color: #ffffff;
      /* Add other styles if necessary */
      /* dolla $ign */
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      /* line-height: 24px; */
      display: flex;
      align-items: center;
      /* letter-spacing: 0.1em; */
      text-transform: uppercase;
    
      color: #ffffff;
    }
}

@media only screen and (max-width: 768px) {
    .player-modal-team-name {
      /* Adjust to more closely resemble the design */
      font-size: 1em; /* Smaller font size */
      letter-spacing: normal; /* Adjust letter spacing */
      margin-bottom: 0; /* Remove bottom margin */
      text-transform: none; /* Remove uppercase */
      color: #ffffff;
      /* Add other styles if necessary */
      /* dolla $ign */
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      /* line-height: 24px; */
      display: flex;
      align-items: center;
      /* letter-spacing: 0.1em; */
      text-transform: uppercase;
    
      color: #ffffff;
    }
}


@media only screen and (min-width: 768px) {
    .player-modal-name {
      /* Style adjustments for the player's name */
      font-size: 2em; /* Larger font size for emphasis */
      font-weight: bold; /* Make the name bold */
      margin-bottom: 5px; /* Space between name and position */
      text-transform: uppercase;
      color: #ffffff;
      /* Dhruv achappa */
      font-family: "Montserrat";
      font-style: italic;
      font-weight: 800;
      /* identical to box height */
      display: flex;
      align-items: center;
      letter-spacing: 0.21em;
      text-transform: uppercase;
      color: #ffffff;
    }
}

@media only screen and (max-width: 768px) {
    .player-modal-name {
      /* Style adjustments for the player's name */
      font-size: 1em; /* Larger font size for emphasis */
      font-weight: bold; /* Make the name bold */
      margin-bottom: 12px; /* Space between name and position */
      text-transform: uppercase;
      color: #ffffff;
      /* Dhruv achappa */
      font-family: "Montserrat";
      font-style: italic;
      font-weight: 800;
      /* identical to box height */
      display: flex;
      align-items: center;
      letter-spacing: 0.21em;
      text-transform: uppercase;
      color: #ffffff;
      margin-top: 12px;
    }
}

@media only screen and (min-width: 768px) {
    .player-modal-position {
      /* Style adjustments for the position */
      font-size: 20px; /* Adjust font size to be smaller than the name */
      margin-bottom: 5px; /* Space between position and price */
      color: #ffffff;
    
      font-family: "Montserrat";
      font-style: italic;
      font-weight: 300;
      font-size: 35px;
      line-height: 43px;
      /* identical to box height */
      display: flex;
      align-items: center;
      letter-spacing: 0.21em;
      text-transform: uppercase;
    
      color: #ffffff;
    }
}

@media only screen and (max-width: 768px) {
    .player-modal-position {
      /* Style adjustments for the position */
      font-size: 20px; /* Adjust font size to be smaller than the name */
      margin-bottom: 5px; /* Space between position and price */
      color: #ffffff;
    
      font-family: "Montserrat";
      font-style: italic;
      font-weight: 300;
      font-size: 16px;
      line-height: 43px;
      /* identical to box height */
      display: flex;
      align-items: center;
      letter-spacing: 0.21em;
      text-transform: uppercase;
    font-weight: 600;
      color: #ffffff;
    }
}

.game-info-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #ffffff;
}

.player-group {
  /* A */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 43px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #ffffff;
}

.statcard-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: start;
  justify-content: center;
  height: 40px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #ffffff;
}
.player-stats {
  background: rgba(175, 198, 219, 0.69);
  border-radius: 5px;
  align-items: center;
}

.player-matches {
  background: rgba(175, 198, 219, 0.69);
  border-radius: 5px;
}

.player-modal-price {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* This will align the text to the right */
  color: #ffffff;
  width: 100%; /* Ensure the container takes full width to push content to the edge */
}

/* Additional custom styles to clean up the collapse appearance */
.coll-dropdown .ui-collapse-title {
  background-color: white; /* Dark background for the collapse title */
  color: white; /* White text for the title */
  padding: 0.75rem 1rem; /* Padding around the title text */
  margin-bottom: 0.5rem; /* Space between collapse headers */
  border: white; /* Remove any default borders */
  border-radius: 4px;
}

/* .green-filter {
  filter: hue-rotate(90deg) brightness(150%);
  position: 'absolute';
  top: '40%';
  left: '49%';
        cursor: 'pointer';
        width: "25px";
        height: "25px";
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.pulsing {
  filter: hue-rotate(90deg) brightness(150%);
  position: 'absolute';
  top: '40%';
  left: '49%';
        cursor: 'pointer';
        width: "25px";
        height: "25px";
  animation: pulse 2s infinite;
} */


.coll-dropdown .nextui-collapse-content {
  background-color: #b2b0b0; /* Dark background for the collapse content */
  padding: 0.5rem 1rem; /* Padding inside the collapse content */
  border: none; /* Remove any default borders */
}

/* Style the checkbox labels to match the design */
.nextui-checkbox-label {
  color: white; /* White text for the labels */
  font-size: 1rem; /* Adjust the font size as needed */
  padding-left: 0.5rem; /* Space between the checkbox and label */
  width: 100%;
  align-items: center;
  text-align: right;
  justify-content: left;
}

/* Adjust the padding and margin of the collapse content to better fit the design */
.nextui-collapse-content {
  padding: 0.5rem; /* Reduced padding inside the collapse content */
  margin: 0; /* Remove margin to make the collapse flush with its title */
}

/* Style for the checkboxes within the collapse */
.nextui-checkbox {
  /* Apply any specific styles you need for the checkbox here */
}

/* Ensure that the collapse headers are cleanly separated */
.nextui-collapse-title {
  margin-top: 0.5rem; /* Add space above the collapse header */
  margin-bottom: 0; /* Remove space below the header for a tighter fit */
}

/* These styles assume a dark theme is used as per the screenshot provided */

.add-player-btn {
  padding: 10px;
  cursor: pointer;
  background-color: white; /* Assuming a white button, but change as needed */
  border: none;
  border-radius: 4px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* Existing sidebar li styles */
.sidebar li {
  background-color: #4dffa8; /* Background color for list items */
  color: black; /* Text color for player name */
  margin-bottom: 10px;
  padding: 15px 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column; /* Stack name and position on top of each other */
  align-items: flex-start; /* Align items to the left */
}

/* Styles for the player cards */
.player-card {
  background-color: #4dffa8; /* Same background color as sidebar li */
  color: black; /* Same text color as sidebar li */
  margin-bottom: 10px;
  padding: 15px 20px;
  border-radius: 4px;
  display: flex;
  align-items: center; /* Align items in a row */
  justify-content: space-between; /* Space between content and button */
}

.player-name,
.player-position,
.player-price {
  margin-bottom: 4px; /* Space between name, position, and price */
}

.add-player-btn {
  padding: 8px 16px;
  background-color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Styles for pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #ffffff; /* Primary Green */
  color: black; /* White text */
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 20px; /* Provide some top margin */

  background: #fdfdfd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.next-page-button {
  background-color: #ffffff; /* Primary Green */
  color: black; /* White text */
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 20px; /* Provide some top margin */

  background: #fdfdfd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.pagination button:hover {
  background-color: #1b8a3a; /* A darker green on hover */
}

/* Add styles for your Submit Button */
.submit-button {
  background-color: #4dffa8; /* Primary Green */
  color: black; /* White text */
  border: none;
  padding: 15px 30px; /* Bigger padding for larger button */
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 20px; /* Provide some top margin */
}

/* Add styles for your Submit Button */
.reset-button {
  background-color: grey; /* Primary Green */
  color: black; /* White text */
  border: none;
  padding: 5px 10px; /* Bigger padding for larger button */
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 20px; /* Provide some top margin */
}
.submit-button:hover {
  background-color: #4dffa8; /* A darker green on hover */
}

/* Add hover effect for buttons */
.add-player-btn:hover,
.pagination button:hover {
  background-color: #3cb371; /* Darker shade for hover effect */
}

/* Optional: Disabled state for buttons */
.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Pagination button styles if not already defined */
.pagination button {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #4dffa8; /* Same as the player card for consistency */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Hover effect for buttons */
.pagination button:hover {
  background-color: #3cb371; /* Darker shade for hover effect */
}

/* Disable button style */
.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* NextUI Dropdown styles if needed */
/* Make the dropdown button full width and background white */
.dropdown-button {
  width: 100%; /* Full width to match the 'Money Left' section */
  background-color: #ffffff; /* White background color */
  border: 1px solid #cccccc; /* Add border to match the design */
  color: black; /* Black color for the text to ensure visibility on a white background */
  padding: 10px 20px; /* Add some padding */
  border-radius: 4px; /* Match border-radius of the 'Money Left' section */
  text-align: center; /* Center the text */
  margin-bottom: 20px; /* Add some margin at the bottom */
  cursor: pointer; /* Change the cursor to indicate it's clickable */
  /* Add any other styling you need to match your design */
}

/* White background for the dropdown menu items */
.dropdown-menu {
  background-color: #ffffff; /* White background color */
  /* Style the dropdown menu further as needed */
}

/* Style for the dropdown item on hover to match the overall design */
.dropdown-item:hover {
  background-color: #e8e8e8; /* Light grey background on hover */
}

.dropdown-item {
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover {
  background-color: #666;
}
