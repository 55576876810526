.homeawardspage {
    position: relative;
}

.homeawardspage:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-image: url('../../../assets/images/GironaFC2.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}

@media screen and (min-width:650px) {
    .homeawardspage:before{
        border-radius: 20px;
    }
}

@media screen and (max-width:650px) {
    .homeawardspage:before{
        border-radius: 10px;
    }
}

.habackg {
    position: relative;
    padding: 5% 10% 2.5% 10%;
}
.haquote{
    text-align: center;
}
