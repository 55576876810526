/* .homepage{
    background-image: url('../../assets/images/AshokaFieldSunset.jpg') !important;
    width: 100%;
    height: auto;
} */

@font-face {
    font-family: 'DS';
    src: url('../../../assets/fonts/Dancing_Script/DancingScript-VariableFont_wght.ttf');
}

.homelandingpage {
    position: relative;
}

.homelandingpage:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-image: url('../../../assets/images/AshokaSunsetCyclingField3.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}
  
.hlbackg {
    position: relative;
    border-color: white !important;
    border-width: 5px;
    padding: 3%;
    font-family: "Maven Pro", sans-serif;
}

.hlquote{
    text-align: center;
}

.nextui-c-iWjDFM-kaRBtV-cv{
    background: black !important;
}
.nextui-c-iWjDFM-hKBzkL-cv{
    border-color: white !important;

}
.nextui-c-iWjDFM-jsIPTU-cv{
    box-shadow: 0px 4px 10px 0 rgb(44, 44, 44) !important;
}
.nextui-c-iTPCAT-VujqA-disableBlur-false{
    background: rgba(0, 0, 0, 0.4) !important;
    backdrop-filter: saturate(100%) blur(10px) !important;
}
.nextui-c-iWjDFM-hkKLfu-cv{
    background: rgba(0, 0, 0, 1) !important;
}
.h-btn{
    color: white !important;
}

.homelanding{
    width: 100%;
    height: 85vh
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
}

.text{
    position: absolute;
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    color: white;
}

@media screen and (max-width: 650px) {
    .text{
        top: 400px
    }
    .text p{
        font-size: 2rem;
        font-weight: 500;
    }
}

@media screen and (min-width: 650px) and (max-width: 850px) {
    .text{
        top: 400px
    }
    .text p{
        font-size: 3.5rem;
        font-weight: 500;
    }
}

@media screen and (min-width: 850px) {
    .text{
        top: 400px
    }
    .text p{
        font-size: 5rem;
        font-weight: 500;
    }
}