.seasonspage{
    position: relative;
}

.seasonspage:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-image: url('../../assets/images/GironaFC2.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}
  
.seasonsbackg {
    position: relative;
    border-color: white !important;
    border-width: 5px;
    padding: 3%;
}